.card_shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}

.gradient_text {
  background: #273cdf;
  background: repeating-linear-gradient(to left, #5330ec 0%, #437fe5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
