.t-body {
  width: auto;
}

.address {
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.address1 {
  max-width: 50%;
  font-size: 0.9rem;
}

.first {
  width: 6rem;
  background-color: white;
  font-size: 0.9rem;
  font-weight: 600;
}

.middle {
  width: 1rem;
  font-size: 0.9rem;
}

.last {
  padding-left: 1rem;
  font-size: 0.9rem;
}

.first1 {
  width: 8.5rem;
  background-color: white;
  font-size: 0.9rem;
  text-align: right;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-weight: 600;
}

.middle1 {
  width: 1rem;
  font-size: 0.9rem;
}

.last1 {
  font-size: 0.9rem;
  padding-left: 1rem;
}

.first3 {
  width: 15rem;
  background-color: white;
  font-size: 0.9rem;
  text-align: left;
}

.middle3 {
  width: 1rem;
  font-size: 0.9rem;
}

.last3 {
  font-size: 0.9rem;
  padding-left: 1rem;
}

.line {
  height: 2px;
  background: gray;
  width: 100%;
  margin-bottom: 2rem;
}

.line1 {
  height: 1px;
  background: black;
  width: 100%;
}

.line2 {
  height: 2px;
  background: gray;
  width: 100%;
}

.dets {
  position: relative;
  bottom: 0.3rem;
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
}

.fullModal {
  width: 80%;
  max-width: none;
  border-radius: none;
  padding: none;
}

.tTable {
  color: blue;
  width: auto;
  width: 100%;
}

.tMainHead {
  padding-left: 1rem;
  background-color: rgba(255, 208, 0, 0.952);
  height: auto;
  width: 100%;
  border-bottom: 1px solid black;
}

.tMainBody {
  padding-left: 1rem;
  color: black;
  font-weight: 600;
  font-size: 0.8rem;
  border-bottom: 1px solid black;
}

.tSubtotal {
  padding-left: 1rem;
  color: black;
  font-weight: 600;
  font-size: 0.8rem;
  border-bottom: 1px solid gray;
}

.item_image,
.mrp,
.quantity,
.discount {
  text-align: center !important;
}
.rate {
  text-align: right !important;
  padding-right: 1rem !important;
}

.tHead {
  min-height: 2rem;
  background-color: rgba(255, 208, 0, 0.952);
  font: 0.75rem;
  color: black;
  font-weight: 600;
}

.terms {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}
