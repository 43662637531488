.card {
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .image {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }

  .details {
    margin-top: 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;

    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      flex-grow: 1;

      .brandName {
        font-weight: 700;
        font-size: 0.8rem;
      }
      .productName {
        font-size: 0.9rem;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.5rem;

    .quantityChangeInput {
      flex-grow: 1;
      text-align: center;
    }

    .button {
      // margin-top: .5rem;
      &.added {
        background-color: rgb(205, 63, 63);
      }
    }
  }

  .priceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .price {
      padding: 0 0 0.25rem 5px;
      transition: all 0.3s ease-in-out;
      width: 100%;

      @media (max-width: 700px) {
        font-size: 90%;
      }
    }

    .mrpPrice {
      /* font-size: 75%; */
      text-decoration: line-through;
      color: #535353;
      font-weight: 400;
      margin: 0 0 5px 0;
      padding: 0 0 0 2px !important;
      transition: all 0.3s ease-in-out;

      @media (max-width: 700px) {
        font-size: 90%;
      }
    }
  }
}
