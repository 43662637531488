.line3 {
  height: 2px;
  background: rgb(204, 204, 204);
  width: 100%;
}

.line4 {
  height: 2px;
  background: rgb(204, 204, 204);
  width: 27rem;
}

.modalButton1 {
  width: 4.6rem;
  height: 1.4rem;
  border-radius: 0.2rem;
  padding-left: 0;
  padding-right: 0;
  background-color: #fff2e8;
  border: 1px solid #ffbb96;
}
.modalButton1:hover,
.modalButton1:active {
  background-color: #fff2e8 !important;
  border: 1px solid #ffbb96 !important;
}
.dets1 {
  position: relative;
  bottom: 0.35rem;
  color: #d4380d;
  font-size: 0.75rem;
}
.fullModal1 {
  width: 80%;
  max-width: none;
  border-radius: none;
  padding: none;
  backface-visibility: visible;
}

.closeButton1 {
  position: relative;
  top: 2.8rem;
  left: 62.5rem;
}

.closeButton1:hover {
  cursor: pointer;
}

.t1Table {
  color: blue;
  width: auto;
  width: 100%;
}
.t1Table2 {
  color: blue;
  width: 100%;
}

.t1MainHead {
  padding-left: 1rem;
  background-color: rgb(204, 204, 204);
  height: auto;
  width: 100%;
  font-size: 1rem;

  border-bottom: 1px solid rgb(204, 204, 204);
}

.t1MainHead2 {
  padding-left: 1rem;
  background-color: rgb(204, 204, 204);
  height: auto;
  width: 100%;
  font-size: 1rem;

  border-bottom: 1px solid rgb(204, 204, 204);
}

.t1MainBody {
  padding-left: 1rem;
  color: black;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 2px solid rgb(204, 204, 204);
}

.t1MainBody1 {
  padding-left: 1rem;
  color: black;
  font-weight: 600;
  font-size: 1rem;
  border: 1px solid rgb(204, 204, 204);
}

.t1Subtotal {
  padding-left: 1rem;
  color: black;
}

.t1Head {
  min-height: 2rem;
  background-color: rgb(204, 204, 204);
  font: 1rem;
  color: black;
  font-weight: 700;
}

.t1Body1 {
  border-right: 2px solid rgb(204, 204, 204);
}