.headerActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-top: 1rem;
  gap: 1rem;
}

.select {
  width: 160px;

  &.narrowSort {
    width: 120px;
  }
}

.search_input {
  min-width: 80px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;

  &.narrow {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1200px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem 0;

  &.narrow {
    justify-content: center;
  }
}
