.body_bg {
  background-color: #bff5da;
  min-height: 100vh;
}
.container_login {
  background-color: white;
}

.img_container {
  max-height: 500px;
  object-fit: cover;
}
