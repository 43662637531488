@use "_variables" as main;

$background-color: #157286;
$primary-color: #157286;
$font-stack: "Inter", sans-serif;
$secondary-color: #000000;
$black-color: #000000;
$orange-color: #fb641b;
$white-color: #ffffff;
$grey-color: #696e79;
$light-grey-color: #7a7a7a;
$lighter-grey-color: #f5f5f6;
$medium-grey-color: #dadada;
$dark-grey-color: #424553;
$grey-color1: #494949;
$grey-color2: #6c757d;
$grey-color3: #c9c6c6;
$grey-color4: #f3f3f3;
$green-color: #00a878;
$clover-green-color: #3ea055;
$pink-color: #ff3e6b;
$brick-red-color: #c84630;
$blue-color: #1c77c3;
$beige-color: #fff6ee;
$dark-black-color: #152536;
$error-red-color: #ed2522;
$red-color: #ff0000;
$blue-color: #1c77c3;
$light-red-color: #fcf6f5;
$light-blue-color: #e8f1f9;
$whatsapp-green-color: #20b038;
$light-green-color: #f2fbf8;
$golden-grey-color: #f5eddf;

body {
  // color: main.$background-color;
  display: block;
  // user-select: none;
}

// font sizes
@for $i from 8 through 64 {
  @if $i % 2==0 {
    .th-font-size-#{$i} {
      font-size: $i + px !important;
    }
  }
}

// font weight
@for $i from 100 through 900 {
  @if $i % 100==0 {
    .th-font-weight-#{$i} {
      font-weight: $i;
    }
  }
}

.primary-color-text {
  color: $primary-color !important;
}

.blue-color-text {
  color: $blue-color !important;
}

.white-color-text {
  color: $white-color !important;
}
.white-color-bg {
  background-color: $white-color !important;
}

.lighter-grey-color-text {
  color: $lighter-grey-color !important;
}

.grey-color-text {
  color: $grey-color !important;
}

.lighter-grey-color-bg {
  background-color: $lighter-grey-color !important;
}

.green-color-text {
  color: $green-color !important;
}

.clover-green-color-text {
  color: $clover-green-color !important;
}

.cursor-pointer {
  cursor: pointer;
}
.ant-table-tbody > tr > td {
  padding: 4px 8px !important;
}
.ant-table-thead > tr > th {
  padding: 6px 10px !important;
}

.unclickable {
  pointer-events: none;
}
