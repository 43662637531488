.container {
  margin: 1.5rem;

  .image {
    width: 270px;
    height: 270px;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
  }

  .imageList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;

    .image {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 12px;
      margin: 0.25rem;
      cursor: pointer;
      border: 2px solid transparent;

      &.selected {
        border: 2px solid #40a9ff;
      }
    }
  }

  .title {
    margin: 1rem 0;
    font-size: 1.25rem;
    font-weight: 500;
  }
  .vendor {
    margin: 0.5rem 0;
    font-size: 1rem;
    font-weight: 400;
  }

  .items {
    margin: 1rem 0;

    .item {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid #ededed;
      padding: 0.25rem;

      .label {
        font-size: 0.8rem;
        flex-grow: 1;
      }
      .icon {
        font-size: 0.8rem;
        color: #333333;
      }
    }
  }

  .details {
    justify-content: space-between;
    margin: 1rem 0;
    .item {
      display: flex;
      // align-items: center;
      cursor: pointer;
      border-bottom: 1px solid #ededed;
      // padding: 0.25rem;

      .label {
        font-size: 1rem;
        flex-grow: 1;
      }
      .value {
        font-size: 1rem;
        flex-grow: 1;
      }
      .icon {
        font-size: 0.8rem;
        color: #333333;
      }
    }
  }

  .button {
    margin: 1rem 0;
    display: block;
    width: 100%;
  }
}
