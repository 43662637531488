.modal {
  width: 50vw;
  height: 90vh;
  background: #fff;
  border-radius: 6px;
}

.bottomSelect {
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
  width: 50vw;
  left: 25vw;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 100vw;
    height: 100vh;
  }
  .bottomSelect {
    width: 100vw;
    left: 0;
  }
}
